import { Card, CardActionArea, CardMedia, CardContent, Typography, TextField, Dialog, DialogTitle, DialogContent, Stack, Autocomplete, Button } from "@mui/material";
import React, { useState } from "react";
export default function OnBoarding() {
    const departments = ["IT", "Tourenlogistik II", "Probenlogistik", "HQ", "Powerservice", "OPS", "Lagerlogistik", "Marketing", "Amazon", "CargoScooter", "Fuhrpark", "AMZL Tourenlogistik II", "Couriermanagement", "Classic", "Kundendienst", "HR", "Disposition", "Lohnverrechnung", "Probenlogistik Lager", "Logistik", "Controlling", "Meetingraum"];
    const cities = ["Wien", "Salzburg", "Innsbruck", "Graz", "Klagenfurt", "Großebersdorf", "Wernberg", "Pasching", "Völs", "Bergheim"]
    const positions = ["Regionalleitung", "Software Developer", "Dispatcher", "TeamleiterIn Probenlogistik", "AssistentIn", "Buchhaltung", "AssistentIn des Geschäftsführers", "Leitung Rechnungswesen", "Kundendienst", "Leitung Kundendienst und Disposition", "Stationsleitung", "Leitung Marketing", "Operation Manager", "Projekt und Prozessmanager", "Produktentwickler", " Chief Technology Officer (CTO)", "Couriermanagement", "Stationsleitung INN", "Receiver", "Geschäftsführer", "CFO", "TelefonistIn", "Personalorganisation", "BetriebsleiterIn", "Fuhrparkmanager", "Projektleiter", "HR", "Gesamt Betriebsleiter", "Junior Data Analyst", "Business Development Manager", "Bereichsleiter Liefer- und Montageservice", "Head of Assistants"]
    const companies = ["Veloce", "Jumug", "Tamburi"]
    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false)
        setDepartment(null)
        setPosition(null)
        setCity(null)
        setCompany(null)
    }
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pwd, setPwd] = useState('');
    const [department, setDepartment] = useState<string | null>();
    const [position, setPosition] = useState<string | null>();
    const [company, setCompany] = useState<string | null>();
    const [city, setCity] = useState<string | null>();
    const onboard = require("./react-pics/onboarding.png");

    const handleActions = () => {
        closeModal();
        CreateOfficeUser();
        CreateDomainUser();
    }
    async function CreateOfficeUser() {
          await fetch(process.env.REACT_APP_URL + "/Office365/CreateUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
            },
            body: JSON.stringify(User)
        })
    }
    async function CreateDomainUser() {
         await fetch(process.env.REACT_APP_URL + "/Domain/CreateUser", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
            },
            body: JSON.stringify(DomainUser)
        })
    }
    const User = {
        displayName: firstName + " " + lastName,
        accountEnabled: true,
        city: city,
        surname: lastName,
        jobTitle: position,
        mobilePhone: phoneNumber,
        department: department,
        givenName: firstName,
        mailNickname: firstName + lastName[0],
        userPrincipalName: firstName + "." + lastName + "@veloce.at",
        passwordPolicies: "DisablePasswordExpiration",
        passwordProfile: {
            forceChangePasswordNextSignIn: true,
            password: pwd
        }
    }
    const DomainUser = {
        firstName: firstName,
        lastName: lastName,
        userLogonName: firstName + "." + lastName + "@momo.local",
        employeeID: "0",
        emailAddress: firstName + "." + lastName + "@veloce.at",
        telephone: phoneNumber,
        address: city,
    }
    return (
        <>
            <Card sx={{ maxWidth: 250, ml: 10, mt: 20 }}>
                <CardActionArea onClick={(event) => setOpen(true)}>
                    <CardMedia component="img" height="250" image={onboard} alt='Active Directory' />
                    <CardContent sx={{ backgroundColor: 'black' }}>
                        <Typography sx={{ color: 'white' }} gutterBottom variant="h5" component="div">Onboarding</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog open={open} onClose={(event) => setOpen(false)}>
                <DialogTitle>Who is going to be On-Boarded?</DialogTitle>
                <DialogContent>
                    <Stack spacing={5} direction={'row'} sx={{ mt: 1 }}>
                        <Stack spacing={2} direction={'column'}>
                            <TextField required variant="filled" label="First Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setFirstName(event.target.value.replace(" ", "")); }}></TextField>
                            <TextField required variant="filled" label="Last Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value.replace(" ", ""))}></TextField>
                            <TextField required type="Password" variant="filled" label="Temporary Password" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPwd(event.target.value)}></TextField>
                            <TextField required variant="filled" label="Phone Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value.replace(" ", ""))}></TextField>
                        </Stack>
                        <Stack spacing={2} direction={'column'}>
                            <Autocomplete sx={{ width: 210 }} onChange={(event, newPos) => { setPosition(newPos) }} defaultValue={null} value={position} options={positions} getOptionLabel={(options) => options} renderInput={(params) => <TextField placeholder="Choose Position" variant='filled' {...params}></TextField>}></Autocomplete>
                            <Autocomplete sx={{ width: 210 }} onChange={(event, newDep) => { setDepartment(newDep) }} defaultValue={null} value={department} options={departments} getOptionLabel={(options) => options} renderInput={(params) => <TextField placeholder="Choose Department" variant="filled" {...params}></TextField>}></Autocomplete>
                            <Autocomplete sx={{ width: 210 }} onChange={(event, newCity) => { setCity(newCity) }} defaultValue={null} value={city} options={cities} getOptionLabel={(options) => options} renderInput={(params) => <TextField placeholder="Choose City" variant="filled" {...params}></TextField>}></Autocomplete>
                            <Autocomplete sx={{ width: 210 }} onChange={(event, newComp) => { setCompany(newComp) }} defaultValue={null} value={company} options={companies} getOptionLabel={(options) => options} renderInput={(params) => <TextField placeholder="Choose Company" variant="filled" {...params}></TextField>}></Autocomplete>
                        </Stack>
                    </Stack>
                    <Button onClick={handleActions} type='submit' variant="contained" color="success" sx={{ height: 40, ml: 25, mt: 2 }}>CREATE</Button>
                </DialogContent>
            </Dialog>
        </>
    )
}