import {BrowserRouter as  Router,Routes ,Route} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import UserManagement from "./pages/UserMgmt";
import AdminVeloce from "./pages/AdminVeloce";
export default function AppRouter(){
    return(
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage/>}/>
                <Route path="home" element={<HomePage/>}></Route>
                <Route path="usermanagement" element={<UserManagement/>}></Route>
                <Route path="adminveloce" element={<AdminVeloce/>}/>
            </Routes>

        </Router>
    )
}
