import { AppBar,  DialogActions,  DialogContent,  DialogTitle,  TextField,  Toolbar } from '@mui/material';
import React, { useEffect, useMemo, useState} from 'react';
import CreateUser from './CreateUser';
import { MRT_ColumnDef, MRT_EditActionButtons, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';

export default function UserManagement() 
{
  const[data , SetUser] = useState<AllUsers[]>([])
  const[newPwd, setNewPwd] = useState('');
  const[rowusername, setRowUsername] = useState('');
  type ApiResponse = {
    data: Array<AllUsers>;
  }
  type AllUsers =  {
    id: number,
    username: string,
    email: string
    firstName: string,
    lastName: string,
    phone: number
    lastLogin: string,
    active: boolean,
    createdAt: string
  }
let navigate = useNavigate();
  useEffect(() => {
       GetAllUsers()
       SetData()
  }, [])
   async function GetAllUsers(){
    try {

      const response = await fetch(process.env.REACT_APP_URL + '/Admin/GetAllUsers', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
        }
      })
      if(response.status === 401){
        navigate("/");
      }
      const AllUsers: any = await response.json() as ApiResponse;
      return AllUsers; 
    } catch {

    }
  }
  async function SetData() {
    return SetUser(await GetAllUsers())
  }
  async function handleSavePwd() {
    const data = {
      username: rowusername,
      password: newPwd,
    }
    const response = await fetch(process.env.REACT_APP_URL + "/Admin/ResetPassword", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
  }
  const handleNewPassword = async (event: React.ChangeEvent<HTMLInputElement> | undefined, row: any) =>  {
    if(!event){
      return;
    }
    setNewPwd(event.target.value)  
    setRowUsername(row.original.username);
  }
  const columns = useMemo<MRT_ColumnDef<AllUsers>[]>(() => [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 40,
    },
    {
      accessorKey: 'username',
      header: 'Username'
    },
    {
      accessorKey: 'email',
      header: 'Email'
    },
    {
      accessorKey: 'firstName',
      header: 'First Name'
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name'
    },
    {
      accessorKey: 'phone',
      header: 'Phone Number'
    },
    {
      accessorKey: 'lastLogin',
      header: 'Last Login'
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At '
    }
  ], [])
  const table = useMaterialReactTable({
    columns, 
    data,
    enableEditing: true,
    onEditingRowSave: handleSavePwd,
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Reset Password</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {<TextField label="New Password" variant="outlined" sx={{mt: 2}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNewPassword(event, row)}></TextField>} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" onClick={handleSavePwd} table={table} row={row} />
        </DialogActions>
      </>
    ),
  });
  return (
    <>
    <div>
      <AppBar position='static'>
        <Toolbar>
     <CreateUser></CreateUser>   
        </Toolbar>
      </AppBar>
      </div>
      <MaterialReactTable table={table}></MaterialReactTable>
      </>
       );
  }