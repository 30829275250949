import { Alert, AppBar, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Stack, TextField, Toolbar } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {  MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable, MRT_EditActionButtons, } from 'material-react-table';
import { useNavigate } from "react-router-dom";
export default function AdminVeloce(){
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState([])
  const [data , setdata] = useState<User[]>([])
  const[Info, setInfoState] = useState(false)
  const[popupstate, setPopupstate] = useState(false)
  const[alert, setAlert] =  useState(false)
  const [auth, setAuth] = useState(true);
  const[newPwd, setNewPwd] = useState('');
  const[rowusername, setRowUsername] = useState('');
  const token = localStorage.getItem("");
  type User = {
    id: number;  
    username: string;
    roles: string[];
  }
  let navigate = useNavigate();
  useEffect( ()  => {
    
     GetVeloceUsers()
     var token: string = localStorage.getItem("token") || "";
     //validateToken(token);
    }, [] ) 
    const GetVeloceUsers = async () => {
      try{

        const response = await fetch(process.env.REACT_APP_URL + '/VeloceUser/GetAllUsers', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
          },
        });
        if(response.status === 401){
          navigate("/");
        }
        const responseData = await response.json() as User[];
        setdata(responseData);
      } catch {
        
      }
    }
 /* function validateToken(token: string): boolean{
   const secret = process.env.REACT_APP_JWT_SECRET || '';
    try{
      const decoded = jwt.verify(token, secret, {
        issuer: "Momo",
        audience: "AdminSphere",
        
      }) as JwtPayload;
      if(decoded.exp && Date.now() >= decoded.exp * 1000) {
        console.warn("TOKEN HAS EXPIRED!")
        return false
      }
    } 
    catch(error) {
      
      console.error('Token validation failed succesfully')
    }
    return true;
  }*/
  const roles = [
    {title: "Admin", value: "admin"},
    {title: "Barcode Scripts", value: "barcodeScripts"},
    {title: "Bellaflora Role", value: "bellafloraRole"},
    {title: "Buchhaltung", value: "buchhaltung"},
    {title: "Courier Management", value: "couriermgmt"},
    {title: "Customer", value: "customer"}, 
    {title: "Driver", value: "driver"},
    {title: "Driver App", value: "driverApp"},
    {title: "Health Advise Line", value: "healthAdviceLine"},
    {title: "Invoice Processor", value: "invoiceProcessor"}, 
    {title: "Junior Ops", value: "juniorOps"},
    {title: "Lagerverwaltung", value: "lagerverwaltung"},
    {title: "MSE Role", value: "mseRole"},
    {title: "Order Placing", value: "orderPlacing"},
    {title: "Senior Ops", value: "seniorOps"},
    {title: "SMS Generic", value: "smsGeneric"},
    {title: "Tier Admin", value: "tierAdmin"},
    {title: "Timeframe Role", value: "timeframeRole"},
    {title: "Verrechnung", value: "verrechnung"},
    {title: "Verrechnung Spezial", value: "verrechnungSpezial"} 
  ]

  const handleRolesChange = (event: any, value: any) => {
    const uniqueRoles = value.filter((role: any, index: number, self: any[]) => self.findIndex((r) => r.value === role.value) === index);
    setRole(uniqueRoles);
  }
  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement> | undefined, row: any) =>  {
    if(!event){
      return;
    }
    setNewPwd(event.target.value)  
    setRowUsername(row.original.username);
  }
  const handleSavePwd = async () => {
    const data = {
      username: rowusername,
      password: newPwd,
    }

    fetch(process.env.REACT_APP_URL + "/VeloceUser/ResetPassword", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
      },
      body: JSON.stringify(data)
    })
  }

  const handleSubmit = async () => {
    const udata = {
      Username: username, 
      Password: password,
      Roles: role.map((selectedRole: any) => selectedRole.value),
    }
    postData(udata);
  }
  function postData(data: any ) {
    fetch(process.env.REACT_APP_URL + '/VeloceUser/CreateUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 201) {
          setPopupstate(false)
          setInfoState(true)
        }
        else{
          setAlert(true)
        }
        return response.json();
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  } 
  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 40
      },
      {
        accessorKey: 'username',
        header: 'Username'
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        
        Cell: ({ cell }) => (
        <div>{cell.getValue<string[]>().join(' | ')}</div>
      ),
      }
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableEditing: true,
    onEditingRowSave: handleSavePwd,
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Reset Password</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {<TextField label="New Password" variant="outlined" sx={{mt: 2}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNewPassword(event, row)}></TextField>} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" onClick={handleSavePwd} table={table} row={row} />
        </DialogActions>
      </>
    ),
  })
   return (
   <div>
    {auth ? (
      <div>
         <div>
        <AppBar position="static">
        <Toolbar>
        <Button  variant='contained' sx={{ backgroundColor: 'green'}} onClick={(event) => setPopupstate(true)}>Create User</Button>
        </Toolbar>
    </AppBar>
    </div>
  
  

    <Dialog open={popupstate} onClose={() => setPopupstate(false)} fullWidth maxWidth="sm">
              <DialogTitle>Create a new User on admin.veloce.at</DialogTitle>
                <DialogContent >
                 <Stack spacing={2} sx={{ ml: 5, mt: 1}} direction={'row'} >
                  <TextField variant='outlined' label="New Username" onChange={(event) => setUsername(event.target.value)}></TextField>
                  <TextField variant='outlined' type='password' label="Password" onChange={(event) => setPassword(event.target.value)}></TextField>
                 </Stack>
                 <Stack spacing={2} sx={{width: 460, ml: 5, mt: 2}}>
                 <Autocomplete multiple id="tags-roles" options={roles} value={role} onChange={handleRolesChange}
                 getOptionLabel={(option) => option.title}
                 renderInput={(params) => (
                  <TextField
                  {...params}  
                  variant='standard'
                  label="Choose Roles"
                  placeholder='Roles'
                  />
                 )}
                 ></Autocomplete>
                 </Stack>
                 </DialogContent>

                <DialogActions>
                  <Stack spacing={2.2} style={{paddingRight: 207}} direction={'row'}>
                    <Button  variant='contained' color='success' onClick={handleSubmit}>Save</Button>
                    <Button variant='contained' onClick={() => setPopupstate(false)} color='error'>Close</Button>
                  </Stack>
                </DialogActions>
            </Dialog>
            <div>
              <MaterialReactTable table={table} />
      <Snackbar open={Info} onClose={() => setInfoState(true)} autoHideDuration={3000}>
        <Alert  severity='success' sx={{width: '100%', fontFamily: 'sans-serif'}}>User created Succesfully</Alert>
      </Snackbar>
      <Snackbar open={alert} onClose={() => setAlert(false)}>
        <Alert severity='error' sx={{width: '100%', fontFamily: 'sans-serif'}}>There was a Problem creating this User</Alert>
      </Snackbar>
       </div>
    
        </div>
    ) : (<><AppBar position="static">
             <Toolbar>
              <Button disabled variant='contained' sx={{ backgroundColor: 'green'}} >Create User</Button>
             </Toolbar>
            </AppBar>
          </>)}
         g
    </div>
    );
}