import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
const image = require("./react-pics/velocelogo.png");
export default function LoginPage() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false);
  const [loginResultMessage, setLoginResultMessage] = useState<string>('');
  const navigate = useNavigate();

  interface LoginResponse {
    token: string;
    message?: string;
    firstname?: string;
    surname?: string;
  }
  React.useEffect(() => {
  localStorage.clear();
   
  }, [])
     const handleLogin = async () => {
      const data: any = {
        email: username,
        Password: password,
      }
      try {
        const response = await fetch(process.env.REACT_APP_URL + '/User/Login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    
        const loginresult: LoginResponse = await response.json();
    
        if (response.ok) {
          // If login is successful, redirect to '/home'
          localStorage.setItem('token', JSON.stringify(loginresult.token));
          localStorage.setItem('Firstname', JSON.stringify(loginresult.firstname));
          localStorage.setItem('Lastname', JSON.stringify(loginresult.surname));
          console.log("Firstname: " + loginresult.firstname);
          console.log("Lastname: " + loginresult.surname);
          navigate('/home');
        } else {
          // Handle unsuccessful login
          setLoginResultMessage(loginresult.message || 'Unknown error occured')
          setOpen(true)
        }
      } catch (error) {
        console.error(error);
      }
    };
  return (
    <>
      <Container component="main" maxWidth="xs" >
        <Box sx={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={image} style={{width: "200px", height: "100px", margin: "0 0 10px 0"}}></img>
          <Typography component="h1" variant="h5"><p>Sign in to AdminSphere</p></Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="email" label="Username or Email Adress" name="email" autoComplete="email" autoFocus onChange={(event) => setUsername(event.target.value)} />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" onChange={(event) => setPassword(event.target.value)} />
            <Button type="button" fullWidth variant="contained" sx ={{ mt: 3, mb: 2}} onClick={handleLogin}>{"Sign in"} </Button>
          </Box>
        </Box>
      </Container> 
    <Snackbar open={open} onClose={(event) => setOpen(false)}>
      <Alert severity='error' title='Password is wrong!'>{loginResultMessage}</Alert>
    </Snackbar>
    </>
);

};