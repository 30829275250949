import { Settings } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";

export default function Profile () {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const initials = (localStorage.getItem("Firstname")  ?? '') + (localStorage.getItem("Lastname") ?? '');
    const replacedPairedQuotes = initials.replace(/""/g, ' ');
    const result = replacedPairedQuotes.replace(/"/g, '');
    console.log(result);     
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => setAnchorEl(null);
    
    function stringAvatar(name: string) {
        const nameParts = name.split(' ').filter(part => part);  // Split by space and filter out empty strings
  
        let initials = '';
        
        if (nameParts.length > 0) {
          initials += nameParts[0][0];  // Add the first letter of the first part
        }
        
        if (nameParts.length > 1) {
          initials += nameParts[1][0];  // Add the first letter of the second part, if available
        }
      
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: initials,
        };
      }
      
      function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    return(
    <>
    <IconButton onClick={handleClick} size="small" sx={{ml: 2}} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
    <Avatar {... stringAvatar(result.toUpperCase())}></Avatar>
    </IconButton>
    <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}  slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
    }} transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={handleClose}>
        <Avatar/> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <Avatar/> My Account
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <ListItemIcon>
            <Settings fontSize="small"></Settings>
        </ListItemIcon>
         Settings
        </MenuItem>

    </Menu>
    </>
)
}