import { Autocomplete, Button, Card, CardActionArea, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Slide, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
export default function OffBoarding() 
{
    const offboard = require("./react-pics/Download.png");
    const [usernames, setUsernames] = useState<string[]>([])
    const [openState, setOpenState] = useState(false)
    let navigate = useNavigate();
      useEffect(() => {
        const fetchUsernames = async () => {
         try{

           const response = await fetch(process.env.REACT_APP_URL + "/Office365/GetUsernames", {
             method: 'GET',
             headers: {
               "Content-Type": "application/json",
               "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
              }
            });
            if(response.status === 401){
              console.log("unauthorized ll")
              navigate("/");
            }
            const data = await response.json();
            const result = data.usernames.result;
            
            if(result)
              setUsernames(result);
          } catch {
          
          }  
        }

        fetchUsernames();
      }, [])
      async function DisableUser() {
        const response = fetch(process.env.REACT_APP_URL + "/Office365/DisableUser", 
        {
          method: 'PATCH',
          headers: {
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${localStorage.getItem("token")?.replace(/"/g, '')}`
          }
        }
        );
      }
return (
    <> 
    <Dialog open={openState} fullWidth maxWidth='xs' onClose={(event) => setOpenState(false)}>
      <DialogTitle>Disable a User</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} sx={{mt: 1}}>
        <Autocomplete style={{width: '100%'}} freeSolo disableClearable options={usernames} renderInput={(params) => <TextField {...params} label="Users"></TextField>} ></Autocomplete>
        <Button onClick={DisableUser} variant="contained" color="error">Disable</Button>
        </Stack>

      </DialogContent>
    </Dialog>
        <Card sx={{ maxWidth: 250, ml: 10, mt: 20 }} >
            <CardActionArea onClick={(event) => setOpenState(true)} >
            <CardMedia  component="img" height="250" image={offboard} alt='Offboarding'/>
            <CardContent sx={{backgroundColor: 'black'}}>
                <Typography sx={{color: 'white'}} gutterBottom variant="h5" component="div">Offboarding</Typography>
                </CardContent>
        </CardActionArea>
        </Card>
    </>
  )
}